  @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

  /* common css */

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  li {
    list-style-type: none;
  }
  a:hover {
    text-decoration: none;
  }
  button {
    cursor: pointer;
  }
  input:focus {
    outline: none;
  }
  textarea:focus {
    outline: none;
  }
  select:focus {
    outline: none;
  }
  
  .container_fluid {
    width: 100%;
    float: left;
    min-height: 100vh;
    background: transparent linear-gradient(180deg, #90194180 0%, #F04F2380 100%) 0% 0% no-repeat padding-box;
  }

  .container {
    max-width: 1280px;
    clear: both;
    padding: 10px;
    margin: 0 auto;
  
  }
  
  img {
    max-width: 100%;
    display: block;
  }
  
  
  input,
  textarea,
  select,
  button {
    font-family: "Quicksand", sans-serif;
  }
  /* a{color: #1897f2;} */
  
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #737584;
    opacity: 1;
  }
  input::-moz-placeholder,
  textarea::-webkit-input-placeholder {
    color: #737584;
    opacity: 1;
  }
  input:-ms-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #737584;
    opacity: 1;
  }
  input:-moz-placeholder,
  textarea::-webkit-input-placeholder {
    color: #737584;
    opacity: 1;
  }
  
  /* header css */
  .header_container_fluid{

    background-color: #901941;
    height: 100px;
    border-radius: 0px 0px 50px 50px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
  }
  .header_content{

    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .header_content h1{

font-size: 40px;
font-family: "Quicksand", sans-serif;
color: #FFFFFF;


  }
 /* CustomDropdown.css */

.custom-dropdown {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  
  .label_dropdown {
    letter-spacing: 0px;
    color: #901941;
    text-align: left;
    font-family: "Quicksand", sans-serif;
    font-size: 30px;
    font-weight: 600;
    padding-left: 20px;
  }
  
  .custom-select {
    background: #FFFFFF;
    border: 2px solid #5A5A5A;
    border-radius: 43px;
    color: #5A5A5A;
    /* padding: 12px; */
    padding-left: 20px;
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    font-size: 20px;
  
  }
  
  .error-message {
    color: #FFFFFF;
    /* color: #901941; */
    font-family: "Quicksand", sans-serif;
    font-weight: 500;
    font-size: 16px;
    padding-left: 20px;
  }
  
  .error .custom-select {
    border-color: red;
  }
  
  .Home_content{
    display: flex;
    justify-content: space-between;
  }
  .camara_style{

    border:2px solid #707070;
    border-radius: 20px;
    height: 350px;
    background-color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .camara_style img{
    width: 40px;
    height: 45px;
  
  }
  .camaranew_style{

    border:2px solid #707070;
    border-radius: 20px;
    /* height: 400px; */
    /* background-color: gray; */
    display: flex;
    justify-content: center;
    background: transparent linear-gradient(180deg, #90194180 0%, #F04F2380 100%) 0% 0% no-repeat padding-box;

   
  }
  .camaranew_style_open{

    /* border:2px solid #707070; */
    border-radius: 20px;
    height: 300px;
    /* background-color: gray; */
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent linear-gradient(180deg, #90194180 0%, #F04F2380 100%) 0% 0% no-repeat padding-box;

   
  }
  .open_images{
    width: 64px;
    height: 64px;
  }
.camaranew_style img{
  width: 100%;
  height: auto;
  border-radius: 20px;
}
 
  .camara_container h1,.camaew_container h1{
    color: #901941;
    text-align: left;
    font-family: "Quicksand", sans-serif;
    font-size: 30px;
    font-weight: 600;
    margin:20px 0px;

  }
  .camara_container p{
    color: #5A5A5A;
    text-align: left;
    font-family: "Quicksand", sans-serif;
    font-size: 30px;
    font-weight: 500;
    margin:20px 0px;
    text-align: center;

  }
  .form_content{
    width: 65%;
  }
  .camara_container{
    width: 30%;
    

  }
  .camaranew_container{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btncontainer{
    margin: 30px;
    display: flex;
    justify-content: center;
  }
  .btncontainer button{
    padding: 15px;
    border-radius: 20px;
    border: 2px solid #901941;
    background-color: #901941;
    color: #FFFFFF;
    font-size: 16px;
    font-family: "Quicksand", sans-serif;
font-weight: 600;
  }

  /* Your main CSS file or a dedicated stylesheet */
.otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}

.otp-input {
   width: 50px;
  height: 40px; 
  font-size: 2em;
  border-radius: 20px;
  border: 1px solid #FFFFFF;
  margin-right: 8px;
  text-align: center;
  color: #5A5A5A;
}

.otp-separator {
 display: none;
}
.mobile_function{
  display: flex;
  justify-content: space-between;
}
.mobile_function p,.mobile_function h5,.react_function p,.reactvisit_function p{
color: #901941;
font-family: "Quicksand", sans-serif;
font-weight: 600;

}
.mobile_function h6{
  color: #089352;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  cursor: pointer;
  
  }
.align_self{
  align-self: center;
}
.mobile_function p{
font-size: 30px;
padding-left: 20px;


}
.react_function{
  margin: 20px 0px;
}
.reactvisit_function{
  margin-top: 20px;
  margin-bottom: 40px;
}
.react_function p,.reactvisit_function p{
  font-size: 30px;
  padding-left: 20px;
}
.mobile_function h5,.mobile_function h6{
font-size: 16px;

align-self: center;
padding-right: 30px;

}

.submi_content_button{
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}
.submit_content p{
  color: #901941;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}
.submit_content button{
  color: #FFFFFF;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  background: #901941;
  border: 1px solid #901941;
  padding: 8px 50px;
  border-radius: 20px;
}


.inputstyle{
background: #FFFFFF;
border: 2px solid #5A5A5A;
border-radius: 43px;
padding: 10px;
width: 100%;
font-family: "Quicksand", sans-serif;
color: #5A5A5A;
font-size: 20px;
padding-left: 20px;
font-weight: 400;
margin-bottom: 5px;
}
.margin_adjust{
  margin-top: 15px;
}
.inputstyle_other{
background: #FFFFFF;
border: 2px solid #5A5A5A;
border-radius: 43px;
padding: 10px;
width: 100%;
font-family: "Quicksand", sans-serif;
color: #5A5A5A;
font-size: 20px;
padding-left: 20px;
font-weight: 400;
margin-bottom: 10px;
}
.disabled{
  background: #CCCCCC;
    /* color: #FFFFFF; */

}
.radio_input{
 font-size: 40px;
}
.visit_heading{

  font-family: "Quicksand", sans-serif;
  color: #901941;
  font-size: 30px;
  font-weight: 600;
  margin:20px 0px;
  padding-left: 20px;
}
.visit_button{

  color: #FFFFFF;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  font-size: 25px;
  text-align: center;
  background: #901941;
  border: 2px solid #901941;
  padding: 15px 70px;
  border-radius: 50px;
}
.visit_save_button{

  color: #FFFFFF;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  background: #901941;
  border: 2px solid #901941;
  padding: 5px 20px;
  border-radius: 50px;
}

.footer_button_container{
display: flex;
justify-content: center;
width: 100%;
margin-bottom: 30px;
}
.footer_button{

  color: #FFFFFF;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  background: #901941;
  border: 2px solid #901941;
  padding: 20px 0px;
  border-radius: 50px;
  width: 100%;
}
.footer_button:hover{

  color: #901941;
  background:#FFFFFF;

}
.visit_save_button_content{
  margin: 10px 0px;
  padding-left: 40px;
}
.others_visit{
  display: flex;
  margin: 10px 0px;
}

.visit_button:hover,.visit_save_button:hover{

  color: #901941;
 background-color: #FFFFFF;
 font-weight: 700;

}
.checkbox_label{
  color: #901941;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-size: 20px;
  padding-left: 10px;
  margin: 10px 0px;
  /* background-color: #FFFFFF; */
}
.list_label{
  color: #5A5A5A;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  font-size: 25px;
  padding-left: 10px;
  /* margin: 10px 0px; */
  /* background-color: #FFFFFF; */
  margin-bottom: 20px;
}
.checkbox_input{
  width: 30px;
  height: 30px;
  margin: 10px 0px;

}
.checkbox_container{

  display: flex;
}
.labelStyle{
  text-align: left;
  color: #901941;
  font-size: 30px;
  font-family: "Quicksand", sans-serif;
font-weight: 600;
padding-left: 20px;
}
/* styles.css */
.responsive-webcam {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
/* .visit_container{
  
} */
/* media css */

/* 
@media (max-width: 500px) {


} */

/* @media (max-width: 575px) {
 .labelStyle{
  padding-left: 0px;
 }
} */
@media (max-width: 500px) {
  .header_content h1{
    font-size: 20px;
  }
}
@media (max-width: 768px) {

.Home_content{
  flex-direction: column;
  padding: 0px 20px;
}
.form_content,.camara_container{
  width: 100%;
}
/* .visit_container{
  display: flex;
  flex-direction: column;
  align-items: center;
} */
.visit_container_sub{
  width: 100%;
}
}

@media (min-width: 768.1px) and (max-width: 1120px) {

  .Home_content{
    flex-direction: column;
    padding: 0px 20px;
  }
  .form_content,.camara_container{
    width: 100%;
  }
  /* .visit_container{
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
  .visit_container_sub{
    width: 100%;
  }
}